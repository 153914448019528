@import "../../style/index.scss";

.original {
  width: 100vw;
  min-height: 100vh;
  padding-top: .76rem;

  &-contents {
    width: 13.5rem;
    margin: 0 auto .56rem;
    text-align: center;

    &-title {
      @include title;
      @include Sp95;
      color: $title-color;
    }

    &-subtitle {
      @include subtitle;
      @include Sp15;
      color: $title-color;
    }
  }

  &-swiper {
    width: 14rem;
    height: 4.82rem;
    position: relative;
    margin: 0 auto;
    margin-bottom: .04rem;

    .swiper-slide {
      border-radius: .21rem;
      overflow: hidden;
    }

    &-wrap {
      width: 98%;
      margin: 0 auto;

      &-video {
        object-fit: cover;
      }
    }

    &-content {
      width: 4.31rem;
      height: 4.31rem;
      border-radius: .21rem;
      overflow: hidden;
    }

    &-box {
      z-index: 99;
      position: absolute;
      top: -0.24rem;
      left: 50%;
      transform: translateX(-52%);
      width: 5rem;
      height: 4.82rem;
      @include bg("swiper-box");
    }
  }

  &-info {
    width: 5rem;
    height: 1.58rem;
    margin: 0 auto;
    @include bg("swiper-info");
    padding: .28rem .4rem;

    &-title {
      font-size: .24rem;
      @include Sp95;
      color: #765EBE;
      margin-bottom: .06rem;
    }

    &-detail {
      font-size: .2rem;
      line-height: .3rem;
      @include Sp65;
      color: $title-color;
    }
  }
}

@media screen and (max-width:800px) {
  .original {
    width: 100%;
    height: auto;
    margin: 0 auto;
    padding-top: 1.26rem;
    min-height: auto;

    &-info {
      width: 4.2rem;
      height: auto;

      &-detail {
        font-size: .18rem;
      }
    }

    &-contents {
      width: 100%;

      &-title {
        width: 100%;
        font-size: .38rem;
        color: #FFFFFF;
        margin-bottom: .26rem;
      }

      &-subtitle {
        width: 90%;
        font-size: .18rem;
        color: $subtitle-color;
        text-align: center;
        line-height: .3rem;
        margin: 0 auto;
        @include Sp25;
      }
    }

    &-swiper {
      width: 100%;
      height: 100%;
      margin: 0 auto .6rem;

      &-content {
        width: 3.9rem;
        height: 3.9rem;
      }

      &-box {
        z-index: 99;
        position: absolute;
        top: -0.2rem;
        left: 50%;
        transform: translateX(-50%);
        width: 4.34rem;
        height: 4.32rem;
      }
    }
  }
}