@import "../../style/index.scss";

.innovation {
  width: 100vw;
  height: 130vh;
  position: relative;

  &-title {
    width: 100%;
    text-align: center;
    @include Sp95;
    @include title;
    position: absolute;
    top: .27rem;
    left: 0;
    color: $title-color;
    z-index: 4;
  }

  &-content {
    width: 100%;
    height: 100%;
  }

  &-swiper {
    width: 100%;
    height: 100%;
  }

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    width: 100%;
    height: 100%;
  }

  .swiper-pagination {
    top: 10rem;
  }

  .swiper-pagination-bullet {
    width: .44rem;
    height: .08rem;
    border-radius: .04rem;
    background-color: $disable-color;
  }

  .swiper-pagination-bullet-active {
    background-color: $primary-text-color;
  }
}

@media screen and (max-width:800px) {
  .innovation {
    width: 100vw;
    height: auto;
    padding-top: 1rem;

    &-title {
      width: 100%;
      text-align: center;
      @include Sp95;
      font-size: .32rem;
      line-height: .49rem;
      position: relative;
      top: auto;
      left: auto;
      color: $title-color;
      z-index: auto;
      padding-top: .2rem;
      margin-bottom: .5rem;
    }
  }
}