iframe {
  display: none;
}

html,
body {
  background-color: #000000;
  overflow-x: hidden;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
    -moz-appearance:textfield;
}

@media screen and (min-width:800px) {
  .Toastify__toast {
    margin-bottom: .1rem !important;
  }

  .Toastify--animate {
    animation-duration: 0.3s !important;
  }

  .Toastify__toast-container {
    width: 430px !important;
    min-width: 72px !important;
    font-size: .24rem !important;
    z-index: 40000 !important;
  }

  .Toastify__close-button {
    align-self: center !important;
  }

  .Toastify__toast-icon {
    margin-top: 4px;
  }
}


@media screen and (max-width:800px) {
  .Toastify__toast-container {
    font-size: .24rem;
  }
}
