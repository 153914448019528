@import "../../../style/index.scss";

.header {
  width: 100vw;
  height: .8rem;
  padding: 0 2.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1002;

  img {
    width: 100%;
    height: 100%;
    display: block;
  }

  &-logo {
    width: 1.2rem;
    height: .6rem;
    cursor: pointer;
  }

  &-nav {
    display: flex;
    align-items: center;
    height: 100%;

    &-items {
      display: flex;
      height: 100%;
      align-items: center;

      &-item {
        display: flex;
        align-items: center;
        margin-right: .3rem;
        font-size: .16rem;
        line-height: .19rem;
        color: $disable-color;
        cursor: pointer;
        @include MEBlack;

        .icon-home {
          background-image: url("../../../assets/icons/home.png");
        }

        .icon-NFT {
          background-image: url('../../../assets/icons/NFT.png');
        }

        .icon-innovation {
          background-image: url('../../../assets/icons/innovation.png');
        }

        .icon-mindmap {
          background-image: url('../../../assets/icons/mindmap.png');
        }

        &:hover {
          color: $primary-text-color;

          .icon-home {
            background-image: url("../../../assets/icons/home-active.png");
          }

          .icon-NFT {
            background-image: url('../../../assets/icons/NFT-active.png');
          }

          .icon-innovation {
            background-image: url('../../../assets/icons/innovation-active.png');
          }

          .icon-mindmap {
            background-image: url('../../../assets/icons/mindmap-active.png');
          }
        }

        &:nth-last-child(1) {
          margin-right: .4rem;
        }

        &-icon {
          display: inline-block;
          width: .3rem;
          height: .3rem;
          background-position: center;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          margin-right: .02rem;
          margin-top: -0.04rem;
        }
      }
    }

    &-socials {
      height: 100%;
      display: flex;
      align-items: center;

      &-item {
        width: .36rem;
        height: .36rem;
        margin-right: .14rem;
        cursor: pointer;

        &:nth-last-child(1) {
          margin-right: 0;
        }
      }
    }
  }
}
