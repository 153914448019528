.loading-screen {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: .5rem;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 100000;

  &-left {
    width: 60vw;
    height: 100vh;
    background-color: #000;
    position: absolute;
    left: 0;
    top: 0;
    position: absolute;
    transform: translateX(0);
    transition: all 2s ease-in-out;
    clip-path: polygon(0 0, 100% 0, 70% 100%, 0% 100%);
  }

  &-right {
    width: 60vw;
    height: 100vh;
    background-color: #000;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(0);
    transition: all 2s ease-in-out;
    clip-path: polygon(30% 0%, 100% 0, 100% 100%, 0% 100%);

  }

  &-item {
    font-size: .5rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
    transition: all 0.5s ease-in-out;

    &-video {
      width: 5rem;
      height: 5rem;
    }
  }
}

.hide-item {
  opacity: 0;
}

.open-left {
  transform: translateX(-158%);
}

.open-right {
  transform: translateX(158%);
}

.site {
  display: none;
}
