@import "../../../style/index.scss";

.series-screen-2 {
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 1.2rem;

  &-content {
    position: relative;
    z-index: 3;
    color: $title-color;
    text-align: center;
    top: 1rem;

    &-title {
      @include title;
      @include Sp95;
      margin-bottom: .22rem;
    }

    &-subtitle {
      font-size: .32rem;
      line-height: .49rem;
      @include Sp95;
      margin-bottom: .16rem;
    }

    &-detail {
      width: 10rem;
      @include Sp15;
      @include subtitle;
      margin: 0 auto;
    }
  }

  &-video {
    width: 14rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    // top: 1rem;
    bottom: 0;

    &-mask {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      @include bg('mask-video');
    }
  }
}

@media screen and (max-width:800px) {
  .series-screen-2 {
    width: 100%;
    height: 5.4rem;
    position: relative;
    overflow: hidden;
    padding-top: 0;

    &-content {
      position: relative;
      z-index: 3;
      color: $title-color;
      text-align: center;
      top: 0;


      &-title {
        font-size: .32rem;
        line-height: .49rem;
        margin-bottom: .4rem;
      }

      &-subtitle {
        font-size: .2rem;
        line-height: .3rem;
        margin-bottom: .11rem;
      }

      &-detail {
        width: 6rem;
        @include Sp25;
        font-size: .18rem;
        line-height: .22rem;
        margin: 0 auto;
      }
    }

    &-video {
      width: 100vw;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 1rem;
    }
  }

  .series-screen-2-video-mask {
    height: 107%;
  }
}