@import '../../../style/index.scss';


.mobilemindmap {
  padding-top: 1.2rem;

  &-wrapper {
    &-title {
      @include Sp95;
      font-size: .32rem;
      line-height: .76rem;
      text-align: center;
      color: #fff;
      margin-bottom: .8rem;
    }

    &-list {
      min-height: 35.66rem;
      // @include bg('mobileLine', '../../../assets/images/mindmap/', )
      background: url('../../../assets/images/mindmap/mobileLine.png') no-repeat center;
      background-size: 1.11rem 35.66rem;

      &_item {
        height: 5.1rem;

        .wrapper {
          display: flex;
          align-items: center;
          justify-content: center;

          &-text {
            color: #fff;
            padding: .1rem;
            flex: 1;

            .title {
              @include Sp95;
              font-size: .2rem;
              line-height: .24rem;
            }

            .subtitle {
              margin-top: .15rem;
              @include Sp95;
              font-size: .14rem;
              line-height: .24rem;
            }

            .desc {
              margin-top: .15rem;
              @include Sp25;
              font-size: .18rem;
              line-height: .2rem;
            }
          }

          &-media {
            flex: 1;

            img {
              transform: scale(1.3);
            }
          }
        }

        &:nth-child(odd) {
          .wrapper-text {
            order: 2;
            padding-left: .4rem;
          }
        }

        &:nth-child(even) {
          .wrapper-text {
            text-align: right;
            padding-right: .4rem;
          }
        }

        &:nth-child(4) {
          padding-right: .5rem;
          margin-top: -0.1rem;
        }

        &:nth-child(5) {
          margin-top: -0.2rem;

          .wrapper-text {
            padding-left: .3rem;
          }
        }

        &:nth-child(7) {
          .wrapper-media {
            img {
              transform: scale(1.3) translateY(15%);
            }
          }
        }
      }
    }
  }
}