@import "../../style/index.scss";

.about-us {
  width: 100vw;
  padding-bottom: .8rem;
  padding-top: .59rem;

  &-content {
    width: 14rem;
    text-align: center;
    margin: 0 auto;

    &-title {
      font-size: .6rem;
      line-height: .92rem;
      @include Sp95;
      color: $title-color;
      margin-bottom: .24rem;
    }

    &-subtitle {
      font-size: .3rem;
      line-height: .46rem;
      @include Sp65;
      color: $title-color;
      margin-bottom: .46rem;
    }
  }

  &-video {
    width: 11.98rem;
    margin: 0 auto;
    border-radius: .24rem;
    overflow: hidden;
  }

  video {
    border-radius: .24rem;
  }
}

@media screen and (max-width:800px) {
  .about-us {
    width: 100vw;
    padding-bottom: .8rem;
    padding-top: 4.9rem;

    &-content {
      width: 90%;
      text-align: center;
      margin: 0 auto;

      &-title {
        font-size: .38rem;
        line-height: .46rem;
        @include Sp95;
        color: $title-color;
        margin-bottom: .26rem;
      }

      &-subtitle {
        font-size: .18rem;
        line-height: .3rem;
        @include Sp25;
        color: $subtitle-color;
        margin-bottom: .46rem;
      }
    }

    &-video {
      width: 86%;
      margin: 0 auto;
      border-radius: .24rem;
      overflow: hidden;
    }
  }
}
