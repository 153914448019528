@import "../../style/index.scss";

.series {
  width: 100vw;
  height: 100vh;

  &-scroll {
    width: 100%;
    height: 100%;

    .swiper {
      width: 100%;
      height: 100%;
    }

    .swiper-slide {
      width: 100%;
      height: 100%;
    }

    .swiper-pagination {
      top: 8rem;
    }

    .swiper-pagination-bullet {
      width: .44rem;
      height: .08rem;
      border-radius: .04rem;
      background-color: $disable-color;
    }

    .swiper-pagination-bullet-active {
      background-color: $primary-text-color;
    }
  }

  &-content {
    width: 100%;
    padding-top: 1.2rem;
  }
}

@media screen and (max-width:800px) {
  .series {
    width: 100vw;
    height: auto;
  }
}