.logo-text {
  width: 100vw;
  height: 100%;
  margin-top: -1rem;
  position: relative;
  transform: scale(0.85);
}

@media screen and (max-width:800px) {
  .logo-text {
    transform: scale(0.8);
  }
}