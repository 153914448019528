@import '../../style/index.scss';

.mindmap {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding-bottom: .84rem;
  background-size: cover;
  overflow: hidden;
  color: #fff;
  height: 100vh;
  cursor: url("../../assets/icons/drag.ico"), default;

  &-wrapper {
    position: relative;

    &-title {
      @include Sp95;
      font-size: .54rem;
      line-height: .83rem;
      text-align: center;
      position: absolute;
      top: .8rem;
      left: 0;
      width: 100%;
    }

    .swiper-slide {
      transition: all .5s linear;
      padding-top: 1.6rem;

      &-next,
      &-prev {
        transform: translateY(0.68rem) !important;
        opacity: .2;
      }

      &-active {
        .wrapper-media {
          img {
            transform: translate(-10%, -50%) !important;
          }
        }
      }
    }
  }

  &-wrapperDesc {
    position: absolute;
    display: flex;
    width: 100%;
    max-width: 14rem;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 30%;
    // transform: translate(0, -60%);
    pointer-events: none;
    z-index: 3;
    margin-top: .2rem;

    .description {
      position: relative;
      width: 100%;
      max-width: 6.85rem;
      min-height: 2rem;
      pointer-events: none;

      .title {
        @include Sp95;
        font-stretch: normal;
        font-style: normal;
        line-height: .43rem;
        padding: 0;
        margin: 0;
        margin-top: .16rem;
        margin-bottom: .24rem;
        font-size: .32rem;
      }

      .subtitle {
        @include Sp95;
        font-stretch: normal;
        font-style: normal;
        line-height: .36rem;
        padding: 0;
        margin: 0;
        margin-bottom: .12rem;
        font-size: .24rem;
      }

      p {
        @include Sp15;
        font-size: .3rem;
        font-stretch: normal;
        font-style: normal;
        line-height: .4rem;
        letter-spacing: normal;
        margin: 0;
        padding: 0;
      }
    }
  }

  .slider-wrapper {
    display: flex;
    align-items: center;



    .wrapper-media {
      position: relative;
      margin: 0 auto;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      width: 40vw;
      height: 40vw;
      justify-content: center;


      img {
        position: relative;
        user-select: none;
        pointer-events: none;
        width: 100%;
        height: 100%;
        object-fit: cover;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: .08rem;
        clip-path: polygon(5% 0, 100% 0, 100% 90%, 90% 100%, 55% 100%, 0 100%, 0 5%);
      }
    }
  }

  .timeline {
    position: absolute;
    width: 100%;
    bottom: 0rem;
    z-index: 4;
    pointer-events: none;

    .pointCircle {
      transition: all .3s linear;
      fill: #999;
    }

    path {
      stroke: #999;
    }

    .pointCircle.active {
      stroke-width: 40;
      stroke: #a4ff0061;
      r: 10;
      fill: #fff;
    }

    // circle:nth-child(2) {
    //   webkit-animation: circle1 3s infinite;
    //   animation: circle1 3s infinite;
    // }

    // circle:nth-child(3) {
    //   webkit-animation: circle2 3s infinite;
    //   animation: circle2 3s infinite;
    // }

    // circle:nth-child(4) {
    //   webkit-animation: circle3 3s infinite;
    //   animation: circle3 3s infinite;
    // }

    // circle:nth-child(5) {
    //   webkit-animation: circle4 3s infinite;
    //   animation: circle4 3s infinite;
    // }

    // circle:nth-child(6) {
    //   webkit-animation: circle5 3s infinite;
    //   animation: circle5 3s infinite;
    // }

    // circle:nth-child(7) {
    //   webkit-animation: circle6 3s infinite;
    //   animation: circle6 3s infinite;
    // }

    // circle:nth-child(even) {
    //   animation: circleEven 1s infinite;
    // }
  }
}


@keyframes circle1 {
  0% {
    fill: rgb(255, 255, 255);
  }

  8.4% {
    fill: #A4FF00;
  }

  16.8% {
    fill: rgb(255, 255, 255);
  }

  25.2% {
    fill: rgb(255, 255, 255);
  }

  33.6% {
    fill: rgb(255, 255, 255);
  }

  42% {
    fill: rgb(255, 255, 255);
  }

  50.4% {
    fill: rgb(255, 255, 255);
  }

  58.8% {
    fill: rgb(255, 255, 255);
  }

  67.2% {
    fill: rgb(255, 255, 255);
  }

  75.6% {
    fill: rgb(255, 255, 255);
  }

  84% {
    fill: rgb(255, 255, 255);
  }

  92.4% {
    fill: rgb(255, 255, 255);
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

@keyframes circle2 {
  0% {
    fill: rgb(255, 255, 255);
  }

  8.4% {
    fill: rgb(255, 255, 255);
  }

  16.8% {
    fill: rgb(255, 255, 255);
  }

  25.2% {
    fill: #A4FF00;
  }

  33.6% {
    fill: rgb(255, 255, 255);
  }

  42% {
    fill: rgb(255, 255, 255);
  }

  50.4% {
    fill: rgb(255, 255, 255);
  }

  58.8% {
    fill: rgb(255, 255, 255);
  }

  67.2% {
    fill: rgb(255, 255, 255);
  }

  75.6% {
    fill: rgb(255, 255, 255);
  }

  84% {
    fill: rgb(255, 255, 255);
  }

  92.4% {
    fill: rgb(255, 255, 255);
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

@keyframes circle3 {
  0% {
    fill: rgb(255, 255, 255);
  }

  8.4% {
    fill: rgb(255, 255, 255);
  }

  16.8% {
    fill: rgb(255, 255, 255);
  }

  25.2% {
    fill: rgb(255, 255, 255);
  }

  33.6% {
    fill: rgb(255, 255, 255);
  }

  42% {
    fill: #A4FF00;
  }

  50.4% {
    fill: rgb(255, 255, 255);
  }

  58.8% {
    fill: rgb(255, 255, 255);
  }

  67.2% {
    fill: rgb(255, 255, 255);
  }

  75.6% {
    fill: rgb(255, 255, 255);
  }

  84% {
    fill: rgb(255, 255, 255);
  }

  92.4% {
    fill: rgb(255, 255, 255);
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

@keyframes circle4 {
  0% {
    fill: rgb(255, 255, 255);
  }

  8.4% {
    fill: rgb(255, 255, 255);
  }

  16.8% {
    fill: rgb(255, 255, 255);
  }

  25.2% {
    fill: rgb(255, 255, 255);
  }

  33.6% {
    fill: rgb(255, 255, 255);
  }

  42% {
    fill: rgb(255, 255, 255);
  }

  50.4% {
    fill: rgb(255, 255, 255);
  }

  58.8% {
    fill: #A4FF00;
  }

  67.2% {
    fill: rgb(255, 255, 255);
  }

  75.6% {
    fill: rgb(255, 255, 255);
  }

  84% {
    fill: rgb(255, 255, 255);
  }

  92.4% {
    fill: rgb(255, 255, 255);
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

@keyframes circle5 {
  0% {
    fill: rgb(255, 255, 255);
  }

  8.4% {
    fill: rgb(255, 255, 255);
  }

  16.8% {
    fill: rgb(255, 255, 255);
  }

  25.2% {
    fill: rgb(255, 255, 255);
  }

  33.6% {
    fill: rgb(255, 255, 255);
  }

  42% {
    fill: rgb(255, 255, 255);
  }

  50.4% {
    fill: rgb(255, 255, 255);
  }

  58.8% {
    fill: rgb(255, 255, 255);
  }

  67.2% {
    fill: rgb(255, 255, 255);
  }

  75.6% {
    fill: #A4FF00;
  }

  84% {
    fill: rgb(255, 255, 255);
  }

  92.4% {
    fill: rgb(255, 255, 255);
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

@keyframes circle6 {
  0% {
    fill: rgb(255, 255, 255);
  }

  8.4% {
    fill: rgb(255, 255, 255);
  }

  16.8% {
    fill: rgb(255, 255, 255);
  }

  25.2% {
    fill: rgb(255, 255, 255);
  }

  33.6% {
    fill: rgb(255, 255, 255);
  }

  42% {
    fill: rgb(255, 255, 255);
  }

  50.4% {
    fill: rgb(255, 255, 255);
  }

  58.8% {
    fill: rgb(255, 255, 255);
  }

  67.2% {
    fill: rgb(255, 255, 255);
  }

  75.6% {
    fill: rgb(255, 255, 255);
  }

  84% {
    fill: rgb(255, 255, 255);
  }

  92.4% {
    fill: #A4FF00;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}