@import "../../style/index.scss";

.build {
  width: 100vw;
  min-height: 100vh;
  padding-top: .76rem;

  &-title {
    @include title;
    @include Sp95;
    color: $title-color;
    margin-bottom: .17rem;
    text-align: center;
  }

  &-content {
    width: 100vw;
    display: flex;
    justify-content: center;

    &-left {
      padding-top: 1.97rem;
      width: 5.4rem;
      margin-right: .65rem;

      &-text {
        @include subtitle;
        @include Sp15;
        color: $title-color;
        margin-bottom: .57rem;
      }

      &-btn {
        width: 2.97rem;
        height: .9rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: .32rem;
        line-height: .49rem;
        color: $btn-text-color;
        @include Sp95;
        @include bg('btn');
        cursor: pointer;
      }
    }

    &-right {
      width: 8.4rem;
      height: 8.4rem;

      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }
}

@media screen and (max-width:800px) {
  .build {
    width: 100%;
    min-height: auto;
    padding-bottom: .54rem;

    &-title {
      font-size: .32rem;
      line-height: .76rem;
    }

    &-content {
      width: 100%;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      &-left {
        padding: 0;
        margin-bottom: .33rem;
        margin-right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;

        &-text {
          font-size: .2rem;
          line-height: .24rem;
          margin-bottom: .33rem;
          text-align: center;
          @include Sp25;
        }

        &-btn {
          width: 1.94rem;
          height: .52rem;
          font-size: .2rem;
          line-height: .3rem;
          margin-bottom: 54.88px;
        }
      }

      &-right {
        width: 3.8rem;
        height: 3.8rem;
      }
    }
  }
}