@import '../../../style/index.scss';

.newInnovation {
  overflow: hidden;
  position: relative;



  .PinContainer {
    width: 400vw;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .innovation-title {
      // width: 100%;
      // text-align: center;
      @include Sp95;
      @include title;
      position: absolute;
      left: calc(100vw / 2);
      transform: translateX(-50%);
      top: 1.2rem;
      z-index: 800;
      color: $title-color;
    }

    .flexbox {
      display: flex;
      flex-wrap: nowrap;
    }
  }
}
