@import "../../../style/index.scss";

.series-screen-1 {
  width: 100%;
  height: 100%;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  color: $title-color;
  text-align: center;
  margin-bottom: .8rem;
  // justify-content: center;
  padding-top: 1.2rem;
  position: relative;

  &-title {
    @include title;
    @include Sp95;
    margin-bottom: .22rem;
  }

  &-subtitle {
    font-size: .32rem;
    line-height: .49rem;
    @include Sp95;
    margin-bottom: .16rem;
  }

  &-content {
    width: 10rem;
    @include Sp15;
    @include subtitle;
    position: relative;
    top: 1rem;
    margin: auto;
    z-index: 3;
  }

  &-image {
    width: 15.43rem;
    // margin-top: 0.5rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: .3rem;

    img {
      width: 100%;
      height: auto;
    }
  }
}

@media screen and (max-width:800px) {
  .series-screen-1 {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $title-color;
    text-align: center;
    padding-top: 0;

    &-title {
      font-size: .32rem;
      line-height: .49rem;
      margin-bottom: .4rem;
    }

    &-subtitle {
      font-size: .2rem;
      line-height: .3rem;
      margin-bottom: .11rem;
    }

    &-content {
      width: 6rem;
      @include Sp25;
      font-size: .18rem;
      line-height: .22rem;
      margin-bottom: .6rem;
      top: 0;
    }

    &-image {
      width: 100vw;
      position: static;
      transform: none;

      img {
        width: 84%;
        height: auto;
      }
    }
  }
}