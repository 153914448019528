@import "../../style/index.scss";

.footer {
  width: 100vw;
  height: 4.97rem;
  padding: .68rem 2.6rem .94rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #212127;

  &-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &-info {
    width: 7.8rem;

    &-logo {
      width: 3.6rem;
      height: .59rem;
      margin-bottom: .08rem;

      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }

    &-descript {
      font-size: .24rem;
      line-height: .28rem;
      color: $title-color;
      @include Sp15;
    }
  }

  &-title {
    font-size: .2rem;
    line-height: .3rem;
    color: $title-color;
    @include Sp95;
    margin-bottom: .16rem;
    height: .3rem;
  }

  &-item {
    font-size: .2rem;
    line-height: .3rem;
    color: $title-color;
    @include Sp65;
    margin-bottom: .08rem;
    opacity: 0.65;
  }

  &-copyright {
    font-size: .2rem;
    line-height: .24rem;
    color: $title-color;
    @include Sp15;
  }


}

@media screen and (max-width:800px) {
  .footer {
    width: 100%;
    height: auto;
    padding: .8rem .4rem .6rem;

    &-top {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    &-info {
      width: 100%;

      &-logo {
        // margin: 0 auto;
        margin-bottom: .16rem;
        width: 2.2rem;
        height: .36rem;
      }

      &-descript {
        margin-bottom: .4rem;
        text-align: left;
        font-size: .18rem;
      }
    }

    &-social {
      margin-bottom: .4rem;
    }

    &-title {
      font-size: .18rem;
    }

    &-item {
      a {
        font-size: .14rem;
      }
    }
  }



  .footer-info-right {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 15%;
    padding: .4rem 0;

    >div {
      text-align: left;
    }
  }
}