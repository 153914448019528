@import "../../../style/index.scss";

.innovation-screen-2 {
  width: 100%;
  height: 100%;
  position: relative;

  &-video {
    width: 12.06rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: .6rem;
  }

  &-content {
    width: 100%;
    padding-top: 2.4rem;
    color: $title-color;
    text-align: center;
    position: relative;
    z-index: 3;

    &-title {
      @include Sp95;
      font-size: .32rem;
      line-height: .46rem;
      margin-bottom: .18rem;
    }

    &-subtitle {
      margin: 0 auto;
      width: 14rem;
      @include Sp15;
      @include subtitle;
    }
  }
}

@media screen and (max-width:800px) {
  .innovation-screen-2 {
    width: 100%;
    height: auto;
    position: relative;
    // padding-top: 1rem;

    &-video {
      width: 8.06rem;
      position: relative;
      left: auto;
      transform: translateX(0);
      top: auto;
    }

    &-content {
      width: 100%;
      color: $title-color;
      text-align: center;
      position: relative;
      z-index: 3;
      padding-top: 2rem;

      &-title {
        @include Sp95;
        @include subtitle;
        margin-bottom: .11rem;
        font-size: .2rem;
      }

      &-subtitle {
        margin: 0 auto;
        width: 6rem;
        @include Sp25;
        font-size: .18rem;
        line-height: .22rem;
      }
    }
  }
}