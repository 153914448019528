@import "../../../style/index.scss";

.sale-out {
  width: 100vw;
  position: relative;

  &-bg {
    display: block;
    width: 100vw;
  }

  &-count {
    position: absolute;
    left: 2.6rem;
    top: 2.56rem;

    &-info {
      font-size: .44rem;
      color: $title-color;
      line-height: .67rem;
      @include Sp95;
      margin-bottom: .75rem;

      span {
        @include Sp95;
      }
    }
  }

  &-airdrop {
    width: 4.96rem;
    height: .9rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .26rem;
    @include Sp95;
    color: #000000;
    cursor: pointer;
    @include bg('remind-btn');
    margin-top: .2rem;
  }

  &-out {
    display: block;
    width: 7.03rem;
    height: 2.79rem;
    margin-bottom: .32rem;
    margin-left: -0.08rem;
  }

  .by-btn {
    width: 4.95rem;
    height: .9rem;
    cursor: pointer;
    @include bg('remind-btn');
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .32rem;
    color: #000000;
    @include Sp95;
    margin-bottom: .28rem;
  }
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

.spinner-border {
  display: inline-block;
  width: .3rem;
  height: .3rem;
  border: 0.28em solid #000000;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: .75s linear infinite spinner-border;
  animation: .75s linear infinite spinner-border;
}

@keyframes spinner-border {
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width:800px) {
  .public-mint {
    width: 100vw;
    height: auto;
    padding-top: 1rem;
    z-index: 3;

    &-video {
      width: 85%;
      position: absolute;
      top: 1.4rem;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
      display: none;
    }

    &-info {
      font-size: .28rem;
      color: #FFFFFF;
      line-height: .43rem;
      @include Sp95;
      margin-bottom: 8rem;
      padding-left: .48rem;
    }

    &-infos {
      font-size: .3rem;
      line-height: .46rem;
      color: #FFFFFF;
      margin-bottom: .16rem;
      @include Sp65;
      text-align: center;

      &-input {
        margin: 0 auto 0.5rem;
      }
    }

    &-btnWrap {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 1rem;
    }
  }
}

span.sale-out-count-info-count {
  @include Sp95;
  color: $primary-text-color;
}

@media screen and (max-width:800px) {
  .sale-out {
    width: 100vw;
    height: auto;
    padding-top: 1rem;
    z-index: 3;

    &-video {
      width: 85%;
      position: absolute;
      top: 1.4rem;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
      display: none;
    }

    &-info {
      font-size: .28rem;
      color: $title-color;
      line-height: .43rem;
      @include Sp95;
      margin-bottom: 6.5rem;
      padding-left: .48rem;
    }

    &-out {
      margin: 0 auto;
      width: 80%;
      height: auto;
    }

    &-btn {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 1rem;
      margin-top: .4rem;
      flex-direction: column;
      align-items: center;
    }

    &-airdrop {
      margin-left: 0;
    }
  }
}
