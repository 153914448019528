@import "../../style/index.scss";

.connect-v2-connect {
  width: 4.96rem;
  height: .9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: .32rem;
  @include Sp95;
  color: #000000;
  cursor: pointer;
  @include bg('remind-btn');
}

.connect-v2-connected {
  width: 4.96rem;
  height: .9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: .32rem;
  @include Sp95;
  color: #000000;
  @include bg('remind-btn');
}
