@font-face {
  font-family: MEBlack;
  // src: url("../../assets/fonts/MonumentExtended-Black.ttf");
  src: url("https://booyellowupload.s3.amazonaws.com/fonts/MonumentExtended-Black.ttf");
  font-display: fallback;
}

@font-face {
  font-family: Sp15;
  // src: url("../../assets/fonts/Shapiro-15-Fly.otf") format("opentype");
  src: url("https://booyellowupload.s3.amazonaws.com/fonts/Shapiro-15-Fly.otf") format("opentype");
  font-display: fallback;
}

@font-face {
  font-family: Sp65;
  // src: url("../../assets/fonts/Shapiro-65-Light-Heavy-Wide.otf") format("opentype");
  src: url("https://booyellowupload.s3.amazonaws.com/fonts/Shapiro-65-Light-Heavy-Wide.otf") format("opentype");
  font-display: fallback;
}

@font-face {
  font-family: Sp95;
  // src: url("../../assets/fonts/Shapiro-95-Super-Extd.otf") format("opentype");
  src: url("https://booyellowupload.s3.amazonaws.com/fonts/Shapiro-95-Super-Extd.otf") format("opentype");
  font-display: fallback;
}

@font-face {
  font-family: MADE;
  // src: url("../../assets/fonts/MADE Soulmaze PERSONAL USE.otf") format("opentype");
  src: url("https://booyellowupload.s3.amazonaws.com/fonts/MADE Soulmaze PERSONAL USE.otf") format("opentype");
  font-display: fallback;
}

@font-face {
  font-family: Sp25;
  // src: url("../../assets/fonts/Shapiro-25SuperFly.ttf");
  src: url("https://booyellowupload.s3.amazonaws.com/fonts/Shapiro-25SuperFly.ttf");
  font-display: fallback;
}