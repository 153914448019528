@import "../../../style/index.scss";

.innovation-screen-3 {
  width: 14rem;
  margin: 0 auto;
  padding-top: 2rem;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;

  &-video {
    width: 7.2rem;
    margin-top: .5rem;
  }

  &-content {
    width: 5rem;
    text-align: right;
    color: $title-color;

    &-title {
      font-size: .32rem;
      line-height: .46rem;
      @include Sp95;
      margin-bottom: .18rem;
      padding-top: 1.5rem
    }

    &-subtitle {
      @include subtitle;
      @include Sp15;
    }
  }
}

@media screen and (max-width:800px) {
  .innovation-screen-3 {
    width: 100%;
    margin: 0;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    &-video {
      width: 4rem;
      margin-top: 0;
    }

    &-content {
      width: 6rem;
      text-align: center;
      color: $title-color;

      &-title {
        @include subtitle;
        @include Sp95;
        margin-bottom: .11rem;
        padding-top: 0;
        font-size: .2rem;
      }

      &-subtitle {
        font-size: .18rem;
        line-height: .22rem;
        @include Sp25;
        margin-bottom: .3rem;
      }
    }
  }

}