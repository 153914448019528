@import "../fonts/style";

// Theme
$title-color: #FFFFFF;
$subtitle-color: rgba(255, 255, 255, 0.8);
$primary-text-color: #A4FF00;
$secondary-text-color: yellow;
$disable-color: rgba(255, 255, 255, 0.4);
$btn-text-color: #000000;

// Font
$font: "Helvetica Neue",
Helvetica,
"PingFang SC",
"Hiragino Sans GB",
"Microsoft YaHei",
Arial,
sans-serif;
$font-size: 32px;

@mixin MEBlack {
  font-family: "MEBlack";
}

@mixin Sp15 {
  font-family: "Sp15";
}

@mixin Sp25 {
  font-family: "Sp25";
}


@mixin Sp65 {
  font-family: "Sp65";
}

@mixin Sp95 {
  font-family: "Sp95";
}

@mixin MADE {
  font-family: "MADE";
}
