@import '../../style/index.scss';

.picwall {
  width: 100%;
  min-height: 94vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
  margin-top: 1rem;

  &-container {
    width: 120%;
    margin-left: -10%;
    position: relative;

    &_transfrom {
      transform: rotateZ(-5deg);
      position: relative;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.8));
        z-index: 2;
      }
    }

    &_text {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;

      &_content {
        font-size: .3rem;
        line-height: .44rem;
        @include Sp65;
        color: #fff;
        width: 14rem;
        padding: .9rem;
        transform: translateX(8%);
        clip-path: polygon(0 0, 96% 0, 100% 18%, 100% 100%, 4% 100%, 0 82%);
        backdrop-filter: saturate(180%) blur(8px);
        background-color: rgba(0, 0, 0, 0.233);
        border: .02rem solid #979797;
        border-radius: .28rem 0 .28rem 0;
        position: relative;

        &::after {
          content: '';
          width: 8%;
          height: 0.02rem;
          background-color: #979797;
          position: absolute;
          top: 7%;
          right: -1.2%;
          transform: rotateZ(49deg) translate(0, -300%);
        }

        &::before {
          content: '';
          width: 8%;
          height: 0.02rem;
          background-color: #979797;
          position: absolute;
          bottom: 7%;
          left: -1.2%;
          transform: rotateZ(49deg) translate(0, 300%);
        }


        // background: url(../../assets/images/picwall-text-bg.png) no-repeat center;
        // background-size: 100% 100%;
      }
    }
  }

  &-marquee-container {
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;

    .marqueee {
      // flex: 0 0 auto;
      // min-width: 100%;
      // z-index: 1;
      // display: flex;
      // flex-direction: row;
      // align-items: center;
      // animation-play-state: running;
      // animation-delay: 0s;
      // animation-direction: normal;
      padding-top: 12%;
      // background-color: #979797;

      &_top {
        // animation: scroll 80s linear infinite running;
        background: url(../../assets/images/picwall/picwall_top.png) repeat-x center;
        background-size: auto 100%;
        animation: wall_scrollx 80s linear 0s infinite forwards;

      }

      &_center {
        // animation: scroll 150s linear infinite running;
        background: url(../../assets/images/picwall/picwall_center.png) repeat-x center;
        background-size: auto 100%;
        animation: wall_scrollx 140s linear 0s infinite forwards;
        margin: .16rem 0;
      }

      &_bottom {
        // animation: scroll 70s linear infinite running;
        background: url(../../assets/images/picwall/picwall_bottom.png) repeat-x center;
        background-size: auto 100%;
        animation: wall_scrollx 40s linear 0s infinite forwards;
      }

      &-imgBox {
        display: flex;

        .imgItem {
          width: 2.4rem;
          margin-right: .1rem;
        }
      }
    }
  }
}



@keyframes scroll {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-50%);
  }
}


@keyframes wall_scrollx {
  0% {
    background-position-x: 0;
  }

  100% {
    background-position-x: 100%;
  }
}

@media screen and (max-width:800px) {
  .picwall {
    width: 100%;
    min-height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin-top: 1.2rem;
    padding: .4rem 0;

    &-container {
      width: 120%;
      margin-left: -10%;
      position: relative;

      &_transfrom {
        transform: rotateZ(-5deg) translateX(4%);
        position: relative;

        &::after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.568));
          z-index: 2;
        }
      }

      &_text {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;

        &_content {
          font-size: .18rem;
          line-height: .3rem;
          @include Sp65;
          color: #fff;
          width: 6.54rem;
          padding: .55rem;
          clip-path: polygon(0 0, 90% 0, 100% 25%, 100% 100%, 10% 100%, 0 75%);
          backdrop-filter: saturate(180%) blur(8px);
          background-color: rgba(0, 0, 0, 0.233);
          border: .02rem solid #979797;
          border-radius: .28rem 0 .28rem 0;
          position: relative;
          transform: translateX(5%);

          &::after {
            content: '';
            width: 17%;
            height: 0.02rem;
            background-color: #979797;
            position: absolute;
            top: 14%;
            right: -4.5%;
            transform: rotateZ(45deg) translate(0, 0%);
          }

          &::before {
            content: '';
            width: 17%;
            height: 0.02rem;
            background-color: #979797;
            position: absolute;
            bottom: 14%;
            left: -4.5%;
            transform: rotateZ(45deg) translate(0, 0%);
          }


          // background: url(../../assets/images/picwall-text-bg.png) no-repeat center;
          // background-size: 100% 100%;
        }
      }
    }

    &-marquee-container {
      overflow-x: hidden;
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;

      .marqueee {
        // flex: 0 0 auto;
        // min-width: 100%;
        // z-index: 1;
        // display: flex;
        // flex-direction: row;
        // align-items: center;
        // animation-play-state: running;
        // animation-delay: 0s;
        // animation-direction: normal;
        padding-top: 30%;
        // background-color: #979797;

        &_top {
          // animation: scroll 80s linear infinite running;
          background: url(../../assets/images/picwall/picwall_top.png) repeat-x center;
          background-size: cover;
          animation: wall_scrollx 60s linear 0s infinite forwards;

        }

        &_center {
          // animation: scroll 150s linear infinite running;
          background: url(../../assets/images/picwall/picwall_center.png) repeat-x center;
          background-size: cover;
          animation: wall_scrollx 100s linear 0s infinite forwards;
          margin: .16rem 0;
        }

        &_bottom {
          // animation: scroll 70s linear infinite running;
          background: url(../../assets/images/picwall/picwall_bottom.png) repeat-x center;
          background-size: cover;
          animation: wall_scrollx 30s linear 0s infinite forwards;
        }

        &-imgBox {
          display: flex;

          .imgItem {
            width: 2.4rem;
            margin-right: .1rem;
          }
        }
      }
    }
  }
}