@import "../../../style/index.scss";

.mobile-header {
  width: 100vw;
  height: .9rem;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  background-color: rgb(0, 0, 0);

  &-container {
    padding: .1rem .6rem;
    display: flex;
    align-items: center;

    &-logo {
      height: .6rem;

      a {
        display: block;
        height: 100%;

        img {
          width: auto;
        }
      }
    }

    &-spec {
      flex: 1;
    }

    &-navBtn {
      display: flex;
      align-items: center;
    }
  }

  .iconUl {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .icon ul li {
    width: .3rem;
    height: .02rem;
    background: #fff;
    transition: all 0.3s;
    border-radius: .19rem;

    &:nth-child(2) {
      margin-bottom: 0;
      margin: .06rem auto;
    }
  }

  .icon ul:hover {
    cursor: pointer;
  }

  .iconUl.active li:nth-child(2) {
    opacity: 0;
  }

  .iconUl.active li:nth-child(1) {
    transform: translateY(.08rem) rotate(45deg)
  }

  .iconUl.active li:nth-child(3) {
    transform: translateY(-0.08rem) rotate(-45deg);
  }
}

.phoneNav .navList {
  margin-top: .8rem;
  padding: 0 .24rem;
}

.phoneNav .pad3 {
  padding: 3vw 0;
}

.phoneNav .navList a {
  width: 100%;
  color: $title-color;
  font-size: .3rem;
  font-weight: 500;
  letter-spacing: .03rem;
  padding: .3rem 0;
  @include Sp65;
}

.phoneNav .navList a img {
  width: .24rem;
}

.phoneNav .navList .active a,
.phoneNav .navList .active,
.navList a:hover {
  text-decoration: none;
}

.phoneNav .navList .item {
  border-bottom: .01rem solid #D8D8D8;
}

.nav-model {
  position: fixed;
  left: 0;
  top: 100%;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 100;
  transition: all 0.3s linear;
  padding-top: 0.6rem;
}

.nav-model.active {
  top: 0;
}

.nav-model .model-content {
  width: 100%;
  height: 100%;
}

.phoneNav {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.spec {
  flex: 1;
}

.navlist-item {
  width: 100%;
  height: .6rem;
  margin-bottom: .6rem;
  display: flex;
  align-items: center;
  font-size: .32rem;
  justify-content: flex-start;
  @include Sp65;
  // color: $primary-text-color;
  color: rgba(255, 255, 255, 0.4);
  padding-left: .2rem;

  &:nth-last-child(1) {
    color: rgba(255, 255, 255, 0.4);
  }

  &:nth-last-child(2) {
    color: rgba(255, 255, 255, 0.4);
  }

  &:nth-last-child(3) {
    color: rgba(255, 255, 255, 0.4);
  }

  &.active,
  &:hover {
    .home {
      background-image: url("../../../assets/icons/home-active.png");
    }

    .nft {
      background-image: url("../../../assets/icons/NFT-active.png");
    }

    .innovation-icon {
      background-image: url("../../../assets/icons/innovation-active.png");
    }

    .mindmap-icon {
      background-image: url("../../../assets/icons/mindmap-active.png");
    }

    color: $primary-text-color;
  }

  &-icon {
    display: inline-block;
    width: .6rem;
    height: .6rem;
    margin-right: .2rem;
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .home {
    background-image: url("../../../assets/icons/home.png");
  }

  .nft {
    background-image: url("../../../assets/icons/NFT.png");
  }

  .innovation-icon {
    background-image: url("../../../assets/icons/innovation.png");
  }

  .mindmap-icon {
    background-image: url("../../../assets/icons/mindmap.png");
  }

  .discord {
    background-image: url("../../../assets/icons/discord-mobile.png");
  }

  .twitter {
    background-image: url("../../../assets/icons/twitter-mobile.png");
  }

  .instagram {
    background-image: url("../../../assets/icons/ins-mobile.png");
  }

  .opensea {
    background-image: url("../../../assets/icons/opensea-mobile.png");
  }

  .X2Y2 {
    background-image: url("../../../assets/icons/x2y2.png");
  }

  .looksrare {
    background-image: url("../../../assets/icons/looksrare.png");
  }

  .social {
    opacity: 0.4;
  }
}
