@import "../../../style/index.scss";

.innovation-screen-1 {
  width: 100%;
  height: 100%;
  position: relative;

  &-video {
    position: absolute;
    left: 0;
    top: 0;
  }

  &-content {
    position: relative;
    z-index: 3;
    padding-left: 2.64rem;
    padding-top: 2.7rem;
    color: $title-color;

    &-title {
      @include Sp95;
      font-size: .32rem;
      line-height: .48rem;
      margin-bottom: .18rem;
    }

    &-subtitle {
      @include Sp15;
      @include subtitle;
      width: 5.6rem;
    }
  }
}

@media screen and (max-width:800px) {
  .innovation-screen-1 {
    width: 100%;
    height: auto;
    position: relative;

    &-video {
      width: 100%;
      position: relative;
      left: auto;
      top: auto;

      video {
        width: 100vw;
        transform: scale(1.5) translateX(-18%);
      }
    }

    &-content {
      position: relative;
      z-index: 3;
      padding-left: 0;
      padding-top: 0;
      color: $title-color;
      text-align: center;

      &-title {
        @include Sp95;
        font-size: .2rem;
        line-height: .3rem;
        margin-bottom: .11rem;
      }

      &-subtitle {
        width: 6rem;
        @include Sp25;
        font-size: .18rem;
        line-height: .22rem;
        margin: 0 auto;
      }
    }
  }
}
