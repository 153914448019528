@import "../../style/index.scss";

.banner {
  width: 100vw;
  min-height: 110vh;
  position: relative;
  overflow: visible;

  &-mint-wrap {
    width: 100vw;
    min-height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    opacity: 0;
    transition: all 1s;

    &.show {
      opacity: 1;
    }
  }

  #bannerVideo {
    position: absolute;
    left: 0;
    top: -0.5rem;
  }

  &-video-mask {
    position: absolute;
    width: 100vw;
    height: 2.76rem;
    left: 0;
    bottom: -0.2rem;
    @include bg('mask-banner-video');
    // background-color: #f66;
    z-index: 0;
  }

  &-loading-logo {
    position: absolute;
    left: -2.8rem;
    top: -0.9rem;
    opacity: 1;
    transition: all 1s;

    &.hide {
      opacity: 0;
    }
  }

  &-content {
    width: 100vw;
    min-height: 100vh;
    padding: 3.03rem 2.6rem 0;
    position: relative;
    z-index: 3;

    &-wrap {
      width: 8.7rem;
      height: 5.3rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &-texts {
        position: relative;

        &-home {
          position: absolute;
          left: 0;
          top: 0;
          padding-top: 1.05rem;
          transition: all 1s;
          opacity: 0;

          &-sologen {
            @include MADE;
            font-size: .36rem;
            line-height: .4rem;
            color: $subtitle-color;

            span {
              @include MEBlack;
            }
          }

          &.hide {
            opacity: 0;
          }

          &.show {
            opacity: 1;
          }
        }

        &-about {
          position: absolute;
          left: 0;
          top: 0;
          color: $title-color;
          transition: all 1s;
          opacity: 0;

          &.show {
            opacity: 1;
          }

          &-title {
            font-size: .44rem;
            line-height: .67rem;
            @include Sp95;
          }

          &-subtitle {
            font-size: .24rem;
            line-height: .36rem;
            @include Sp65;
          }
        }
      }

      &-video {
        width: 3.56rem;
        height: auto;
        position: relative;
        opacity: 0;
        transition: all 1s;

        img {
          width: 100%;
          display: block;
        }

        &-play {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: .7333rem;
            height: .7333rem;
            cursor: pointer;
          }
        }

        &.show {
          opacity: 1;
        }
      }
    }
  }
}

.video_model {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  display: none;
  z-index: 10000;
}

.model_close {
  position: absolute;
  right: .5rem;
  top: .5rem;
  display: inline-block;
  width: 50px;
  height: 50px;
  overflow: hidden;
  color: #fff;
  background: #fff;
  z-index: 9;
}

.model_close::before,
.model_close::after {
  content: "";
  position: absolute;
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  background: #000;
}

.model_close::before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.model_close::after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.model_content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

@media screen and (max-width:800px) {
  .banner {
    width: 100vw;
    min-height: auto;
    position: relative;

    &-video {
      width: auto;
      // height: 100vh;
      position: absolute;
      left: 0;
      top: .5rem;
      // right: -6rem;
      // left: auto;
      // top: -3rem;
      // transform: scale(0.7);
      overflow: hidden;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.4);
      }

    }

    #bannerVideo {
      position: static;
    }

    &-loading-logo {
      top: 1.6rem;
      left: -40%;


    }

    &-content {
      width: 100vw;
      min-height: auto;
      padding: 4.07rem .75rem 0;

      &-wrap {
        width: 100%;
        height: 5.3rem;

        &-texts {
          position: relative;

          &-home {
            padding-top: 1.1rem;

            &-sologen {
              font-size: .24rem;
              line-height: .31rem;
              text-align: center;
            }
          }

          &-about {

            &-title {
              font-size: .32rem;
              line-height: .49rem;
              text-align: center;
            }

            &-subtitle {
              font-size: .24rem;
              line-height: .28rem;
              text-align: center;
            }
          }
        }

        &-video {
          margin: 0 auto;
        }
      }
    }
  }

  .model_close {
    transform: scale(0.5);
  }

  .mobile-video-box {
    position: relative;
    z-index: 4;
    margin-top: 2.5rem;

    .banner-content-wrap-video {
      width: 6.4rem;
      margin: auto;
      opacity: 1;
    }

    .banner-content-wrap-video-play {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }

  .banner-content-wrap-texts-about {
    margin-top: .5rem;
  }
}
