@mixin clearfix {
  zoom: 1;

  &:after {
    content: "";
    display: block;
    clear: both;
    overflow: hidden;
  }
}

@mixin no-scroll {
  ::-webkit-scrollbar {
    display: none !important;
    width: 0;
    height: 0;
  }
}

@mixin title {
  font-size: .54rem;
  line-height: .83rem;
}

@mixin subtitle {
  font-size: .3rem;
  line-height: .4rem;
}
